<template>
  <b-card-code title="جدول الطلبة الخريجين" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- filter -->
        <b-form-group
          label="فلتر"
          label-cols-sm="1"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="title"
              type="search"
              placeholder="ابحث عن .."
            />
            <v-select
                v-model="department"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="departments"
                placeholder="اختر القسم"
              />
            <b-input-group-append>
              <b-button @click="resetSearch()">
                الغاء الفلتر
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-modal.model-a-w
              >
                <feather-icon icon="FilePlusIcon" class="mr-50" />
                <span class="align-middle">اضافة خريجين جدد</span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>
<div class="demo-vertical-spacing">
      <b-progress
      v-if="precentage !== 0"
        :value="precentage"
        max="100"
      />
      </div>
    <b-table
      ref="table"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :items="items"
      :fields="fields"
    >
      <template #cell(action)="action">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              v-b-modal.modal-center
              @click="dRM(action.item)"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
            </b-button>
          </b-col>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          @change="check()"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
        @change="check()"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
    <b-modal
      id="model-a-w"
      ok-title="حفظ البيانات"
      centered
      size="lg"
      title="اضافة خريجين جدد"
      @show="checkModel"
      @ok="aNM"
      @hidden="rF"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
        <b-col md="12">
            <label for="input-valid1">اختر ملف الخريجين (اكسل حصرا):</label>
            <b-form-file
              placeholder="هل تريد حذف هذه الحقل"
              drop-placeholder="هل تريد حذف هذه الحقل"
              v-model="form.file"
               @change="selectFile"
            />
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">القسم</label>
              <v-select
                v-model="form.department_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="departments"
                placeholder="اختر القسم"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      title="حذف الخريج"
      @ok="dM"
      ok-title="حذف الحقل"
    >
      <b-card-text>
        هل تريد حذف هذه الحقل " {{ this.editform.name }} "
        ?
      </b-card-text>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import store from "@/store";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormCheckboxGroup,
  BForm,
  BFormRow,
  BCol,
  VBModal,
  BBadge,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,BProgress,
  BInputGroupAppend,
  BButton,BFormFile,
  BCardBody,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,BProgress,
    BForm,
    vSelect,
    BAvatar,
    BBadge,
    BFormRow,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    togglePasswordVisibility,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormCheckboxGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,BFormFile,
    BCardBody,
    BModal,
    VBModal,
    Ripple,
    BCol,
    BFormCheckbox,
    BCardText,
    BFormValidFeedback,
    BFormInvalidFeedback,
  },
  data() {
    return {
      precentage:0,
      teacher_id: "",
      perPage: 3,
      pageOptions: [3, 5, 10, 100],
      totalRows: 1,
      currentPage: 0,
      department:"",
      title:"",
      filter: null,
      filterOn: [],
      departments: [],
      teachers: [],
      stages: [],
      fields: [
        {
          key: "name",
          label: "الاسم",
        },
        {
          key: "year",
          label: "السنة الدراسية",
        },
        {
          key: "dwor",
          label: "نوع الدراسة",
        },
        {
          key: "department.ar_name",
          label: "القسم",
        },
        { key: "action", label: "الاجراءات" },
      ],
      items: [],
      form: {
        file: "",
        department_id:""
      },
      editform: {
        name: "",
        id: null,
      },
      selected: [],
      selected2: [],
    };
  },
  watch: {
    department: function (val) {
      this.gS();
    },
    title: function (val) {
      this.gS();
    },
    "editform.department_id": function (val) {
      if (val != null || val!="" ) {
        this.gSM1()
      }
    },
    "form.department_id": function (val) {
      if (val != null || val!="" ) {
        this.gSM3()
      }
    },
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.ID(), this.gR(), this.gSM(),this.gSM2()
  },
  methods: {
    async gSM() {
      await this.axios
        .get(`since/deparment?take=${this.perPage}&skip=${
            this.currentPage - 1
          }&select=true`)
        .then((res) => {
          this.departments = [];
          this.departments = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSM1() {
      this.stages = []
      this.editform.stage_id==""
      console.log(this.form.department_id)
      if(! Number.isInteger(this.editform.department_id)){
        this.editform.department_id = this.editform.department_id["id"]
      }
      await this.axios
        .get(`stage/clint/department/${this.editform.department_id}?select=true`)
        .then((res) => {
          this.stages = [];
          this.stages = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSM3() {
      this.stages = []
      this.form.stage_id==""
      if(! Number.isInteger(this.form.department_id)){
        this.form.department_id = this.form.department_id["id"]

      }
      await this.axios
        .get(`stage/clint/department/${this.form.department_id}?select=true`)
        .then((res) => {
          this.stages = [];
          this.stages = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSM2() {
      this.teachers = []
      this.editform.teacher_id==""
      await this.axios
        .get(`teachers-name`)
        .then((res) => {
          this.teachers = [];
          this.teachers = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    checkModel() {
      this.selected = [];
      if (this.editform.is_sustainabilty == 1) {
        this.selected.push("is_sustainabilty");
      }
      if (this.editform.is_energy == 1) {
        this.selected.push("is_energy");
      }
    },
    resetCheckboxes() {
      this.selected = [];
    },
    async uM() {
      if(! Number.isInteger(this.editform.department_id)){
        this.editform.department_id = this.editform.department_id["id"]
      }
      if(! Number.isInteger(this.editform.stage_id)){
        this.editform.stage_id = this.editform.stage_id["id"]
      }
      if(! Number.isInteger(this.editform.teacher_id)){
        this.editform.teacher_id = this.editform.teacher_id["id"]

      }
      await this.axios
        .post(`lecture/${this.editform.id}`, this.editform,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
          }
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async aNM() {
      if(! Number.isInteger(this.form.department_id)){
        this.form.department_id = this.form.department_id["id"]
      }
      let data = new FormData();
      data.append("department_id", this.form.department_id,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        });
      data.append("file",this.form.file)
      await this.axios
        .post(`students/graguated`, data)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "update Success",
              "Your model is added successfully"
            );
          }
          this.rF();
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();
    },
    rF() {
      this.form.file = ""
    },
    rEF() {
      this.editform.name = "",
      this.editform.id = ""
    },
    async dM() {
      await this.axios
        .delete(`students/graguated/${this.editform.id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    errorToast() {
      this.makeToast(
        "danger",
        "update failed",
        "some thing want wrong, i`m sorry :("
      );
    },
    eRM(item) {
      console.log(item)
      this.editform.en_name = item.en_name;
      this.editform.ar_name = item.ar_name;
      this.editform.stage_id = item.stage_id;
      this.editform.department_id = item.department_id;
      this.editform.teacher_id = item.teacher_id;
      this.editform.id = item.id;
    },
    dRM(item) {
      this.editform.name = item.name;
      this.editform.id = item.id;
    },
    check() {
      setTimeout(() => this.gR(), 1000);
    },
    ID() {
      this.teacher_id = localStorage.getItem("NisourTeacherId");
    },
    async gS() {
      if(! Number.isInteger(this.department)& this.department!=''){
        this.department = this.department["id"]
      }
      await this.axios
        .get(
          `students/graguated?take=${this.perPage}&skip=${
            this.currentPage - 1
          }&department=${this.department}&title=${this.title}`
        )
        .then((res) => {
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gR() {
      await this.axios
        .get(`students/graguated?take=${this.perPage}&skip=${this.currentPage - 1}`)
        .then((res) => {
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    resetSearch(){
      this.title = '',
      this.department = ''
      this.gR()
    },
    selectFile(e) {
      this.form.file=""
      console.log(e.target.files)
      console.log(e.target.files[0])
      this.form.file = e.target.files[0]
      console.log(this.form.file)
    },
  },
};
</script>
